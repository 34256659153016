/**
 * External Dependencies
 */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

/**
 * Internal Dependencies
 */
import './styles/_index.scss';

const Portal = ({
    isOpen,
    onClose,
    children
}) => {
    useEffect(() => {
        isOpen
            ? document.documentElement.style.overflow = 'hidden'
            : document.documentElement.style.overflow = 'unset';
    }, [isOpen]);

    if (!isOpen) return null;

    return ReactDOM.createPortal((
        <div className="portal">
            <div className="portal__wrapper">
                <div className="portal__inner">
                    {children}
                    <i className="icon-close" onClick={onClose}></i>
                </div>
            </div>
        </div>
    ),
        document.getElementById('portal')
    )

}

export default Portal;
