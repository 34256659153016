/**
 * External Dependencies
 */
import React, { useState } from "react"
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect';
import { Link } from "gatsby"


/**
 * Internal Dependencies
 */
import Portal from "../portal/portal";
import "./styles/_index.scss";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import getVideoId from 'get-video-id';
import { Nav, Modal } from "react-bootstrap"

const VideoPlaceholder = ({ image, setIsOpen, isOpen, imagetype, ggfxname, altid, imageprocess, imageid, videourl, html5video, linkto, videoTour, propertyLink, videoTour2 }) => {
  const [play, setPlayVideo] = useState(false);
  const [VideoURL, setVideoURL] = useState(false);
  const playvideo = () => setPlayVideo(true);
  var video_url= videourl;
  if(videoTour) {
    videourl = videoTour[0];
    video_url = videourl;
  }
  const videoid = videourl && getVideoId(videourl);
  if(videoid && videourl=="") {
      video_url = "https://www.youtube-nocookie.com/embed/" + videoid.id;
  }
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
  }
  //console.log('aaaaaaaaaa 1', video_url, VideoURL)
  return (
    <div className="video-placeholder">
      {imagetype === "GGFX" ?
      <Link to ={propertyLink?propertyLink:""}>
        <ImageTransform imagesources={image} renderer="srcSet" imagename={ggfxname} attr={{ alt: altid}} imagetransformresult={imageprocess} id={imageid}/>  
      </Link>
      :
      <Link to ={propertyLink?propertyLink:""}>
        <img src={image} alt={altid} />
      </Link>
      }
      {
        videoTour?.length > 0 &&
        <div className="play-icon" onClick={() => { setIsOpen(true); playvideo();setVideoURL(video_url);}}>
          <i className="icon-play-btn"></i>
        </div>
      }
      {
        videourl?.length > 0 &&
        <div className="play-icon" onClick={() => { setIsOpen(true); playvideo();setVideoURL(video_url);}}>
          <i className="icon-play-btn"></i>
        </div>
      }
      {
        html5video?.length > 0 &&
        <div className="play-icon" onClick={() => { setIsOpen(true); playvideo();setVideoURL(video_url);}}>
          <i className="icon-play-btn"></i>
        </div>
      }
      {
        videoTour2?.length > 0 &&
        <div className="play-icon" onClick={handleShow}>
          <i className="icon-play-btn"></i>
        </div>
      }
      {VideoURL &&
      <Portal
        onClose={() => {setIsOpen(false);}}
        isOpen={isOpen}>
          <ReactPlayer 
          frameBorder="0" 
          allowFullScreen 
          url={VideoURL}
          modestbranding="1"  
          controls={true} 
          autoPlay={true} 
          muted={isIOS ? true : false} 
          playsinline={true} 
          playing={play} 
          width='100%' 
          height='100vh'  
          previewTabIndex={0} 
          light={false}
          />
      </Portal>
      }
      {/* Video modal */}
      <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-fullscreen property-details-modal"
      >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="p-0">
              <iframe src={videoTour2 && videoTour2[0]}></iframe>
          </Modal.Body>
      </Modal>
      {/* Video modal */}
    </div>
  )
}

export default VideoPlaceholder;