export const isMobile =
  typeof window !== "undefined"
    ? window.matchMedia("(max-width: 767px)").matches
    : false
export const isTablet =
  typeof window !== "undefined"
    ? window.matchMedia("(max-width: 975px)").matches
    : false

export const isBrowser = typeof window !== "undefined";
